import React from "react"
import { graphql } from "gatsby"
import { Styled, Box,} from "theme-ui"
import ContactSection from '../components/ContactSection'
import {Content,  ContentWithImage, ContentBlock, ContentWithImageBlock } from "../blocks/content"
import { useLocalJsonForm } from "gatsby-tinacms-json"
import Layout from "../components/layout"
import BasicHero from "../components/BasicHero"
import InstallationSteps from '../components/InstallationSteps'



export default function ApplicationsPage({ data }) {
  const [page] = useLocalJsonForm(data.application, ApplicationForm)
  const blocks = page !== null && page.blocks ? page.blocks : []
  return (
    <Layout>
      { page.hero !== null && page.hero.image !== null && 
        <BasicHero 
          fluid={page.hero.image.childImageSharp.fluid}  
          title={"Man Welding Hero Image"} 
          heroTitle={page.hero.headline}
          shapeColor={'transparent'}
        />
      }
      {/* <h1>Hello world!</h1> */}
      {blocks &&
        blocks.map(({ _template, ...data }, i) => {
          switch (_template) {

          //   case "TitleBlock":
          //     // return <Title page={page} data={data} />
          //   case "ImageBlock":
          //     // return <Image data={data} />
            case "ContentWithImageBlock":
              if (data.content && page.childrenApplicationsJsonBlockMarkdown[i])
                return (
                  <ContentWithImage
                    data={data}
                    html={
                      page.childrenApplicationsJsonBlockMarkdown[i]
                        .childMarkdownRemark.html
                    }
                  />
                );
              break;
            case "ContentBlock":
              if (data.content && page.childrenApplicationsJsonBlockMarkdown[i])
                return (
                  <Content
                    data={data}
                    html={
                      page.childrenApplicationsJsonBlockMarkdown[i]
                        .childMarkdownRemark.html
                    }
                  />
                );
              break;
            default:
              return true;
          }
          return false;
        })}

      <InstallationSteps />

      <ContactSection title='Contact Us' alternate>
        <Box color={'white'}>
          <Styled.h2>How to Buy</Styled.h2>
          <Styled.p>Our team can help you find the solutions you need. Get in touch with us for quotes and exclusive offers.</Styled.p>
        </Box>
      </ContactSection>

    </Layout>
  )
}

const ApplicationForm = {
  label: "Application Case",
  fields: [
    {
      label: "Title",
      name: "rawJson.title",
      component: "text",
    },
    {
      label: "Excerpt",
      name: "rawJson.excerpt",
      component: "text",
    },
    {
      label: "Excerpt Image",
      name: "rawJson.excerpt_image",
      component: "image",
      parse: (filename) => `../images/${filename}`,
      uploadDir: () => `/content/images/`,
      previewSrc: (formValues) => {
        if (!formValues.excerpt_image || !formValues.excerpt_image.image)
          return ""
        return formValues.excerpt_image.childImageSharp.fluid.src
      },
    },
    {
      label: "Hero",
      name: "rawJson.hero",
      component: "group",
      fields: [
        {
          label: "Headline",
          name: "headline",
          component: "text",
        },
        {
          label: "Textline",
          name: "textline",
          component: "text",
        },
        {
          label: "Image",
          name: "image",
          component: "image",
          parse: (filename) => `../images/${filename}`,
          uploadDir: () => `/content/images/`,
          previewSrc: (formValues) => {
            if (!formValues.hero || !formValues.hero.image)
              return ""
            return formValues.hero.image.childImageSharp.fluid.src
          },
        },
      ],
    },
    {
      label: "Page Sections",
      name: "rawJson.blocks",
      component: "blocks",
      templates: {
        ContentWithImageBlock,
        ContentBlock,
        // FormBlock,
        // ContentBlock,
      },
    },
  ],
}


export const useQuery = graphql`
  query($path: String!) {
    application: applicationsJson(path: { eq: $path }) {
      title
      hero {
        headline
        image {
         childImageSharp {
           fluid(quality: 70, maxWidth: 1920) {
             ...GatsbyImageSharpFluid_withWebp
           }
         }
        }
      }
      blocks {
        _template
        content
        align
        background_color
        font_color
        image {
         childImageSharp {
           fluid(quality: 70, maxWidth: 1920) {
             ...GatsbyImageSharpFluid_withWebp
           }
         }
        }
      }

      childrenApplicationsJsonBlockMarkdown {
        childMarkdownRemark {
          html
        }
      }

      rawJson
      fileRelativePath
    }
    purpleBG: file(relativePath: { eq: "purple-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
