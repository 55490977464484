/** @jsx jsx */

import { Box, jsx, Flex, Styled } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Container } from './item'
import Card from './Card'


const Arrow = styled.div`
  display: inline-block;
  margin-top: 8em;

  :before {
    position: relative;
    content: "";
    display: inline-block;
    width: 2em;
    height: 2em;
    border-right: .5em solid black;
    border-top: .5em solid black;
    transform: rotate(45deg);
    margin-right: 0.5em;
  }



  @media screen and (max-width: 768px) {
    display: none;
  }
  
`



const InstallationSteps = ({ props }) => {
  const data = useStaticQuery(graphql`
    query {
      step1: file(relativePath: { eq: "installation-step1.png" }) {
        childImageSharp {
          fluid(maxWidth: 283) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      step2: file(relativePath: { eq: "installation-step2.png" }) {
        childImageSharp {
          fluid(maxWidth: 283) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      step3: file(relativePath: { eq: "installation-step3.png" }) {
        childImageSharp {
          fluid(maxWidth: 283) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return (

        <Box>
          <Container py={13} sx={{textAlign: 'center'}} color="black">
            <Styled.h2 sx={{marginBottom: '40px'}}>installation</Styled.h2>
            <Flex sx={{justifyContent: 'space-between', flexDirection: ['column', 'row'], alignItems: ['center', 'flex-start']}}>
              <Card
                color='black'
                title="Step 1: Cleaning and Prep"
                titleColor='black'
                fluidImg={data.step1.childImageSharp.fluid}
                roundEdges
                shadow
                text="All surfaces should be properly, professionally cleaned before tint is applied."
              >
              </Card>
              <Arrow />
              <Card
                color='black'
                title="Step 2: Measurement"
                titleColor='black'
                fluidImg={data.step2.childImageSharp.fluid}
                roundEdges
                shadow
                text="Windows should be carefully measured and the tint cut oversize to ensure an accurate application."
              >
              </Card>
              <Arrow />
              <Card
                color='black'
                title="Step 3: Finishing"
                titleColor='black'
                fluidImg={data.step3.childImageSharp.fluid}
                roundEdges
                shadow
                text="Tint is applied from the bottom, smoothed, and trimmed. Professional installation reduces the risk of bubbles or creases."
              >
              </Card>
            </Flex>
          </Container>
        </Box>
  )
}

export default InstallationSteps